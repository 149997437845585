.graph1Container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}
.g1Row1 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.g1Row2 {
  width: 100%;
  height: 100%;
  /* position: relative; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
/* .g1Row2Col1 {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  font-size: 2rem;
} */
.g1Row2Col1 {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.9rem;
  margin-right: 14px;
}
.g1Row2Col2 {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.multiDiv {
  width: 1400px;
}
.g1Row3 {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 450px;
}
.g1Row3Col1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.g1Row2Col2Col1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-around;
}
.txtInput {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #243d42;
  text-align: center;
  width: 75px;
  border: 1px solid grey;
  border-radius: 5px;
}
/* .btn {
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: normal;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  width: 75px;
  height: 35px;
  font-size: 1rem;
  border: 1px solid grey;
} */
.graphTitle {
  font-family: Gotham;
  font-size: 25px;
  line-height: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}
