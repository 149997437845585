.appContainer {
  display: flex;
  flex-direction: column;
  font-family: Gotham;
  background-color: #f9f9f9;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham-Light.otf) format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: 900;
  src: local("Gotham"), url(./fonts/Gotham-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: 900;
  src: local("Gotham"), url(./fonts/Gotham-Black.otf) format("opentype");
}
