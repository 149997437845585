.dashboardContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 80vh;
}
.dashRow1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dashRCol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  align-items: center;
}
.dashCol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  align-items: center;
}
.dashCol2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  align-items: center;
}
.dashCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  height: 35%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000000;
}
.dashCardHeader {
  height: 40px;
  font-family: Gotham;
  font-size: 28px;
  line-height: 40px;
  color: #243d42;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  font-weight: 500;
}
