.fGraphContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
}
.jobDetail {
  display: flex;
  width: 300px;
  position: absolute;
  top: 3%;
  left: 3%;
  /* opacity: 0.6; */
}
.rDetail {
  display: flex;
  width: 300px;
  position: absolute;
  top: 3%;
  right: 3%;
  /* opacity: 0.6; */
}
.fGraph {
  position: relative;
  width: 100%;
  height: 100%;
}
.graphCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.graphCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #f5f5f5;
}
.graphCard_lite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000000;
}
.graphCard_kf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  position: relative;
  width: 390px;
  height: 475px;
  /* min-height: 475px; */
  overflow-y: scroll;

  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  /* Elevation / Large - highlight */

  box-shadow: 4px 8px 16px rgba(48, 48, 108, 0.32);
  border-radius: 8px;
}
.graphCardTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #f5f5f5;
}
.graphCardTitle_lite {
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
}
.graphCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.graphCardHeader_lite {
  position: absolute;
  width: 227px;
  height: 28px;
  left: 0px;
  top: 0px;

  font-family: Gotham;
  font-size: 20px;
  line-height: 28px;

  color: #243d42;
}
.graphCardBody {
  font-size: 0.8em;
  color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
  margin-top: -25 px;
}
.graphCardBody_lite {
  font-size: 0.8em;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
}
.pageTitle {
  font-family: Gotham;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
}
.panelTitle {
  font-family: Gotham;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}
.panelItems {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #243d42;
}
.panelHeader {
  position: absolute;
  width: 72px;
  height: 28px;
  left: 0px;
  top: 0px;
  font-family: Gotham;
  font-size: 20px;
  line-height: 28px;
  color: #201f1e;
}
