.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0px 0px 10px #000000;
}
.title {
  position: static;
  height: 40px;
  left: 0px;
  top: 0px;
  font-weight: 600;
  font-family: Gotham;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  /* Copy */

  color: #243d42;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
